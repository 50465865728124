/* eslint-disable */

export default {
  getHtml(deviceData,sgData) {
    return `
          <div style="width:320px;">
            <div style="background-color: #007ffe;height: 60px;padding: 5px 0px;border-radius: 8px 8px 0px 0px;">
                <div style="margin-left: 10px;height: 100%;display: flex;">
                  <span style="font-size: 18px;color: white;align-self: center;">设备编号:${deviceData.deviceId}</span>
                </div>
            </div>

            <div style="background-color: white;height: 100px;">
              <div style="margin-left: 10px;height: 30%;display: flex;">
                <span style="font-size: 18px;color: black;display: flex;">时间:${sgData.createTime.substring(0,16)}</span>
                <span style="font-size: 18px;color: black;display: flex;">(${sgData.modeName})</span>
              </div>
              <div style="margin-left: 10px;height: 70%;display: flex;">
                <span style="font-size: 18px;color: black;display: flex;">地址:${sgData.address}</span>
              </div>
            </div>

          </div>
          <div style="width: 0; height: 0;
                      border-left: 10px solid transparent;
                      border-right: 10px solid transparent;
                      border-top: 10px solid #FFF;
                      position: relative;
                      left: 50%;
                      margin-left: -10px;">
          </div>
            `;

    // return `<div style="background-color: #285df0;padding-left: 28px;padding-top: 10px;padding-bottom: 10px;height: 60px;">
    //           <span style="font-size: 15px;color: white;">绑定用户：${lnglats.userName}</span>
    //           <br/>
    //           <span style="font-size: 15px;color: white;">设备名称：${lnglats.userName}</span>
    //           <br/>
    //           <span style="font-size: 15px;color: white;">设备型号：${lnglats.userName}</span>
    //       </div>
    //       <div style="padding: 7px;height: 210px;width: 100%;background-color: white;">
    //       <div>
    //         <div style="background-color: white;height: 30px;width: 300px;line-height: 30px;margin-bottom: 3px;padding-left: 21px;" >
    //           <span style="font-size: 17px;color: black;">设备使用概括：</span>
    //           <br/>
    //           <span style="font-size: 13px;color: black;">设备编号：${lnglats.userName}</span>
    //           <br/>
    //           <span style="font-size: 13px;color: black;">设备电量：${lnglats.userName}</span>
    //           <br/>
    //           <span style="font-size: 13px;color: black;">设备状态：${lnglats.userName}</span>
    //           <br/>
    //           <span style="font-size: 13px;color: black;">安装名称：${lnglats.userName}</span>
    //           <br/>
    //           <span style="font-size: 13px;color: black;">安装位置：${lnglats.userName}</span>
    //           <br/>
    //           <span style="font-size: 13px;color: black;">详细地址：${lnglats.userName}</span>
    //         </div>
    //       </div>
    //       </div>
    //      `;
  }

}
//  <span style="background-color: white;padding: 8px 14px;color: rgba(83, 199, 200, 1);font-size: 13px;" onclick="tiaozhuan()">个人档案</span>
