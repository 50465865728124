<template>
  

  <div class="page" v-if="mainPage">
    <div>
      <el-collapse-transition>
        <div class="numBox" v-show="numShow">
          <div style="padding:0px 10px;display: flex;">
            <div style="margin-right:10px;">
              <span>全部</span><span>(20)</span>
            </div>
            <el-divider direction="vertical"></el-divider>
            <div style="margin-right:10px;">
              <span>围栏内</span><span>(20)</span>
            </div>
            <el-divider direction="vertical"></el-divider>
            <div style="margin-right:10px;">
              <span>围栏外</span><span>(20)</span>
            </div>
            <el-divider direction="vertical"></el-divider>
            <div>
              <span>未定位</span><span>(20)</span>
            </div>
          </div>
        </div>
      </el-collapse-transition>
    </div>
    
    <div >
      <el-collapse-transition>

        <div class="district-box" style="width:600px;" v-show="circelView">
            <div style="height:40%;display: flex;"><span style="align-self:center;font-weight: bold;font-size: 18px;">{{title}}</span></div>
            <div style="height:60%;display: flex;justify-content: center;align-items:center;">
              <div>
                <ls-address @adr-change="getCircelAddress" :num="3"></ls-address>
              </div>
              <el-button plain style="margin-left:10px;color: #007FFE;" @click="startEditCircle" >编辑圆形</el-button>
              <el-button plain style="margin-left:10px;color: #007FFE;" @click="next(1)">下一步</el-button>
              <el-button plain style="margin-left:10px;color: #007FFE;" @click="cancel(1)">取消</el-button>
            </div>
        </div>
      </el-collapse-transition>
    </div>

     <div >
      <el-collapse-transition>
        <div class="district-box" style="width:600px;" v-show="polygonView">
          <div style="height:40%;display: flex;"><span style="align-self:center;font-weight: bold;font-size: 18px;">{{title}}</span></div>
          <div style="height:60%;display: flex;justify-content: center;align-items:center;">
            <div>
              <ls-address @adr-change="getPolygonAddress" :num="3" :province="this.address.province" :city="this.address.city" :district="this.address.district"></ls-address>
            </div>
            <el-button plain style="margin-left:10px;color: #007FFE;" @click="next(2)" >下一步</el-button>
            <el-button plain style="margin-left:10px;color: #007FFE;" @click="cancel(2)">取消</el-button>
          </div>
        </div>
      </el-collapse-transition>
    </div>

    <div >
      <el-collapse-transition>
        <div class="district-box-title" style="width:600px;" v-show="titleView">
          <div style="height:100%;display: flex;align-self: center;width: 100%;">
            <span style="text-align: center;font-weight: bold;font-size: 20px;width: 75%;">{{title}}</span>
            <span style="text-align: center;font-weight: bold;font-size: 20px;width: 35%;color:#007FFE">围栏内:{{fenceInNum}}</span>
            <span style="text-align: center;font-weight: bold;font-size: 20px;width: 35%;color: red;">围栏外:{{fenceOutNum}}</span>
            <span style="text-align: center;font-weight: bold;font-size: 20px;width: 35%;color: grey;">未知:{{fenceNoNum}}</span>
            <el-button style="align-self: center;margin-left:10px;color: #007FFE;" size="small" @click="editFence">编辑</el-button>
          </div>
        </div>
      </el-collapse-transition>
    </div>

    <div>
      <el-collapse-transition>
        <div class="device-box" v-show="deviceShow">
            <div class="device-box-top">
                <div class="device-title">
                    <span style="margin-left:10px;">监控围栏</span>

                        <el-dropdown trigger="click" placement="bottom" @command="addFence">
                        <el-button plain style="margin-right:10px;color: #007FFE;" >添加围栏</el-button>
                        <template #dropdown>
                          <el-dropdown-menu>
                            <el-dropdown-item :command="{type:1}">自定义圆形</el-dropdown-item>
                            <el-dropdown-item :command="{type:2}">行政区划分</el-dropdown-item>
                          </el-dropdown-menu>
                        </template>
                      </el-dropdown>

                    
                </div>
            </div>

            <div>
                <el-table @row-click="rowClick"  :data="dataList" :show-header="false" style="width: 100%;border-radius: 10px;" :header-cell-style="{ background: '#285df0'}" :empty-text="emptyText">
                  <el-table-column label="设备"  label-class-name="tablehead" align="center " >
                    <template #default="scope">
                      <div style="width:100%;height:50px;">
                        <div style="display: flex;height:60%;font-size: 17px;">
                          <span style="align-self:center">{{scope.row.name}}</span>
                          <span style="align-self:center">({{scope.row.deviceNumber}})</span>
                          <el-image style="margin-left:10px;align-self:center;width: 25px; height: 25px;cursor: pointer;" @click="addDevice(scope.row)"  :src="require('../../../assets/img/device-add.png')"></el-image>
                        </div>
                        <div style="display: flex;height:40%;font-size: 10px;color: #8a8a8a;">
                          <span style="align-self:center">管理人:</span>
                          <span style="align-self:center">{{scope.row.noticeName}}</span>
                          <el-image style="margin-left:10px;align-self:center;width: 15px; height: 15px;cursor: pointer;" @click="switchNotice(scope.row)" :src="require('../../../assets/img/switch.png')"></el-image>
                        </div>
                      </div>
                    </template>
                  </el-table-column>

                <el-table-column label="操作" label-class-name="tablehead" align="center " width="100">
                  <template #default="scope">
                    <el-switch v-model="scope.row.fenceSwitch" @change="turnOn(scope.row)"> </el-switch>
                    <span class="textbtn" style="margin-left:5px" @click="deleteFence(scope.row)">删除</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>

        </div>
      </el-collapse-transition> 
    </div> 
  
     <div class="box"> 
      <div id="map" class="amap-wrapper"></div>
    </div>
  </div> 

  <template v-if="this.addPage">
    <FenceAdd ref="addView" @update="update" @cancel="this.addPage=false"></FenceAdd>
  </template>
  <template v-if="managerPage">
    <FenceAddManager @update="updateManager" @cancel="this.managerPage = false"></FenceAddManager>
  </template>
  <template v-if="devicePage">
    <FenceAddWear ref="deviceView" @update="updateWear" @cancel="this.devicePage = false"></FenceAddWear>
  </template>
  <template v-if="this.editPage">
    <FenceEdit ref="editView" @update="update" @cancel="this.editPage=false"></FenceEdit>
  </template>
</template>



<script  src='./js/fence.js'></script>

<style scoped>
.page {
  padding: 0px;
}
.device-box-title {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 1;
  background-color: #007FFE;
  padding: 9px 0px;
  border-radius: 5px;
  width:330px;
  height:20px;
}
.device-box {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 1;
  background-color: white;
  border-radius: 5px;
  width: 330px;
  /* height: 500px; */
}

.device-box-top {
  width:100%;
  height:60px;
  background-color: #007FFE;
  border-radius: 5px 5px 0px 0px;
}

.device-title {
  width:100%;
  height:100%;
  display: flex;
  align-items: center;
  justify-content:space-between;
  color:white;
  font-size: 18px;
}



.tablehead {
  color: white;
}

.numBox {
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: 1;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.district-box {
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: 1;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  height: 80px;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center;  */
}
.district-box-title {
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: 1;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  height: 30px;
  /* text-align: center; */
  display: flex; 
  align-items: center;
  justify-content: center; 
}
.item {
  margin-right: 5px;
}

.box {
  width: 100%;
  height: 100%;
  position:absolute;
  /* transform: scale(1.2); */
}
.amap-wrapper {
  width: 100%;
  height: 100%;
  margin-right: 10px;
  
  /* position: fixed; */
}
.textbtn {
  color: #285df0;
  cursor: pointer;
}
/* #map {
  width: 100%;
  height: 100%;
  position: fixed;
} */

</style>

<style>
.amap-info-content {
    overflow: hidden;
    padding: 0px;
  }

</style>