<template>
  <div>
     <el-dialog v-model="dialogVisible" :title="title" width="40%" :close-on-click-modal="false" 
              :close-on-press-escape="false" :show-close="false" >
      <div class="row">
        <span>围栏名称：</span>
        <el-input v-model="fenceData.name" placeholder="输入围栏名称" style="width: 80%" maxlength="8" show-word-limit ></el-input>
      </div>
      <div class="row" v-if="this.fenceData.type == 1">
        <span>中心地址：</span>
        <span class="content">{{fenceData.address}}</span>
      </div>
      <div class="row" v-if="this.fenceData.type == 1">
        <span>圆形半径：</span>
        <!-- <span class="content">{{fenceData.radius}}米</span> -->
        <el-input v-model="fenceData.radius" placeholder="输入围栏半径" style="width: 80%" type="number">
          <template #append>
            <span>米</span>
          </template>
        </el-input>
      </div>

      <div class="row" v-if="this.fenceData.type == 2">
        <span>城市名称：</span>
        <span class="content">{{fenceData.districtAddress.replaceAll("#","")}}</span>
      </div>

      <div class="row">
        <span>预警方式：</span>
        <el-select style="width: 80%" v-model="fenceData.warningType" placeholder="设备状态" size="large" clearable>
          <el-option v-for="item in warningTypeOption" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>

      <div class="row">
        <span>管&ensp;理&ensp;人：</span>
        <el-input v-model="fenceData.noticeName" placeholder="请选择管理人" style="width: 80%" disabled>
          <template #append>
            <el-button type="primary" style="color: white" @click="this.managerPage = true">选择管理</el-button>
          </template>
        </el-input>
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel" size="small">取消</el-button>
          <el-button type="primary"  size="small" @click="submit" >确定</el-button>
        </span>
      </template>


    </el-dialog> 
    <template v-if="managerPage">
      <FenceAddManager @update="updateManager" @cancel="this.managerPage = false"></FenceAddManager>
    </template>

  </div>

 

</template>

<script>
import {
  addFenceInfo
} from '../../../api/api'
import session from "../../../store/store";
import until from '../../../until/until'
import FenceAddManager from './FenceAddManager.vue'
export default {
  components: {
    FenceAddManager
  },
  data() {
    return {
      dialogVisible: true,
      managerPage:false,
      fenceData:{
        warningType:1,
      },
      title:'',
      warningTypeOption:[
        {value: 1,label: '出围栏预警'},
        {value: 2,label: '入围栏预警'},
        {value: 3,label: '出入围栏都预警'}
      ],
    };
  },
  methods: {
    getParentData(data) {
      this.fenceData = data;
      this.fenceData.name = '';
      this.fenceData.noticeUuid = '';
      this.fenceData.noticeName = '';

      if(1 == this.fenceData.type) {
        this.title = '新建圆形围栏'
      }else if(2 == this.fenceData.type) {
        this.title = '新建行政区围栏'
      }
    },
    submit() {
      if(until.isNULL(this.fenceData.name)) {
        this.$message.error({ message: '请输入围栏名称'});
        return
      }

      if(parseInt(this.fenceData.radius) == 0) {
        this.$message.error({ message: '请输入围栏有效半径'});
        return
      }
      this.fenceData.unitUuid = session.getLoginInfo().unitUuid
      addFenceInfo(this.fenceData).then(res => {
        if (res.data.code == 200) {
          this.$message.success({ message: "新增成功"});
          this.cancel()
          this.update()
        }else {
          this.$message.error({ message: res.data.message});
        }
      })
    },
    updateManager(data) {
      this.fenceData.noticeUuid = data.uuid;
      this.fenceData.noticeName = data.name;
    },
    cancel() {
      this.dialogVisible = false;
      this.$emit("cancel");
    },
    update() {
      this.$emit("update");
    },
  },
};
</script>

<style scoped>
.row {
  display: flex;
  justify-content:left;
  align-items: center;
  /* margin: 10px 0px; */
  padding: 10px;
}



.title {
  margin-right: 10px;
  font-size: 16px;
  color: #8D8D8D;
}

.content {
  font-size: 16px;
  margin-left: 8px;
}
</style>