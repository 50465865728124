import {
  getFenceInfos,
  deleteFenceInfo,
  editFenceInfo,
  updateFenceDeviceInfo,
  getFenceDeviceSgInfos
} from '../../../../api/api'
import mapInfo from './map-info'
import FenceAdd from "../FenceAdd.vue";
import FenceAddManager from '../FenceAddManager.vue'
import FenceAddWear from '../FenceAddWear.vue'
import FenceEdit from '../FenceEdit.vue'
import session from "../../../../store/store";
export default {
  components: {
    FenceAdd,
    FenceAddManager,
    FenceAddWear,
    FenceEdit
  },
  data() {
    return {
      emptyText: '加载中',
      mainPage: true,
      addPage: false,
      managerPage:false,
      devicePage:false,
      editPage:false,
      deviceShow: true,
      numShow: false,
      circelView: false,
      polygonView: false,
      titleView: false,
      popoverVisible: true,
      infoWindow: null, //窗体
      infoCircelWindow: null, //窗体
      map: null,//地图实例对象
      iconBlue: {},
      iconRed: {},
      circleMarker: null,
      citysearch: null,
      circle: null,
      circleEditor: null,
      polygon: null,
      title: null,
      fenceData: {
        uuid: '',
        name: '',
        address: '',
        radius: '100',
        lat: '',
        lng: '',
        adcode: '',
        type: 1,
        onSwitch: 1,
        warningType:1,
        districtAddress: '',
        level: 0,
        fenceSwitch: true
      },
      rowData: {},
      dataList: [],
      MAXSIZE: 5,
      address: {
        province: '',
        city: '',
        district: '',
        street: '',
        detail: '',
      },
      funcType:0,//1:新增  2:编辑
      fenceInNum:0,
      fenceOutNum:0,
      fenceNoNum:0,
    }
  },

  mounted() {
    this.init()
  },
  unmounted() {
    if(this.map != null) {
      this.map.destroy()
      this.map = null
    }
  },
  methods: {
    addFence(command) {
      this.map.clearMap();
      this.funcType = 1
      this.titleView = false
      if (1 == command.type) {//自定义圆形
        this.title = '自定义圆形'
        this.circelView = true;
        this.polygonView = false;
        this.getLocalCity();
      } else if (2 == command.type) {//行政区
        this.title = '行政区划分'
        this.circelView = false;
        this.polygonView = true;
      }
    },
    seeFence() {
      this.map.clearMap();
      this.titleView = true
      this.circelView = false;
      this.polygonView = false;
      if (1 == this.rowData.type) {//自定义圆形
        this.title = this.rowData.name
        // var lnglat = [this.rowData.lng, this.rowData.lat];
        var lnglat = new AMap.LngLat(this.rowData.lng, this.rowData.lat)
        this.addMarker(lnglat);
        this.setInfoWindow(this.rowData.address);
        this.addCircle(lnglat);
      } else if (2 == this.rowData.type) {//行政区
        this.title = this.rowData.name
        var cityLevel = 'province'
        var citys = this.rowData.districtAddress.split("#");
        var adcode = this.rowData.adcode
        if (1 == this.rowData.level) {
          cityLevel = 'province';
          this.address.province = citys[0]
        } else if (2 == this.rowData.level) {
          cityLevel = 'city';
          this.address.province = citys[0]
          this.address.city = citys[1]
        } else if (3 == this.rowData.level) {
          cityLevel = 'district';
          this.address.province = citys[0]
          this.address.city = citys[1]
          this.address.district = citys[2]
        }
        this.addPolygon(cityLevel, adcode);
      }
    },

    init() {
      this.initMap();
      this.getFenceInfos();
    },


    initMap() {
      this.map = new AMap.Map('map', {
        // resizeEnable: true,
        zoom: 17,
        // viewMode:'3D', //开启3D视图,默认为关闭
      });
      // this.map.setDefaultCursor('pointer');
     
      this.map.on("click", (e) => {
        if (this.circelView) {
          this.map.clearMap()
          this.fenceData.lng = e.lnglat.getLng().toString();
          this.fenceData.lat = e.lnglat.getLat().toString();
          var lnglat = [e.lnglat.getLng(), e.lnglat.getLat()];
          this.addMarker(lnglat);
          this.getAddressWithLngAndLat(lnglat);
          this.addCircle(lnglat)
        }
      });

      this.infoWindow = new AMap.InfoWindow({
        anchor: 'bottom-center',
        offset: new AMap.Pixel(6, 0),
      });
      this.infoCircelWindow = new AMap.InfoWindow({
        anchor: 'bottom-center',
        offset: new AMap.Pixel(0, -32),
      });

   
    
      this.iconBlue = new AMap.Icon({
        image: require("../../../../assets/img/point-green.png"),
        size: new AMap.Size(14, 23),
      });
      this.iconRed = new AMap.Icon({
        image: require("../../../../assets/img/point-red.png"),
        size: new AMap.Size(14, 23),
      });
      AMap.plugin('AMap.ToolBar', () => {
        this.map.addControl(new AMap.ToolBar({
            // position: {
            //   bottom: '100px',
            //   left: '130px'
            // }
        }));
      })
    },
    getLocalCity() {
      // AMap.plugin('AMap.CitySearch', () => {
      //   if (null == this.citysearch) {
      //     this.citysearch = new AMap.CitySearch();
      //   }
      //   this.citysearch.getLocalCity((status, result) => {
      //     console.log(status, result)
      //     if (status === 'complete' && result.info === 'OK') {
      //       // 查询成功，result即为当前所在城市信息
      //       this.map.setBounds(result.bounds);
      //     }
      //   });
      // })

      this.$notify.info({ title: '提示', message: '请选择目标地点' });
    },
    //逆向地理编码
    getAddressWithLngAndLat(lnglat) {
      AMap.plugin('AMap.Geocoder', () => {
        var geocoder = new AMap.Geocoder();
        geocoder.getAddress(lnglat, (status, result) => {
          if (status === 'complete' && result.info === 'OK') {
            // result为对应的地理位置详细信息
            this.fenceData.adcode = result.regeocode.addressComponent.adcode
            this.fenceData.address = result.regeocode.formattedAddress;
            this.setInfoWindow(result.regeocode.formattedAddress);
          }
        })
      })
    },

    addMarker(lnglat) {
      this.circleMarker = new AMap.Marker();
      this.circleMarker.setPosition(lnglat);
      this.map.add(this.circleMarker);
    },

    setInfoWindow(address) {
      var info = [];
      info.push("<div class='input-card content-window-card'> ");
      info.push("<p style='padding:10px' class='input-item'>" + address + "</p></div></div>");
      this.infoCircelWindow.setContent(info.join(""));
      this.infoCircelWindow.open(this.map, this.circleMarker.getPosition());

      this.circleMarker.on("click", () => {
        if (!this.infoCircelWindow.getIsOpen()) {
          this.infoCircelWindow.open(this.map, this.circleMarker.getPosition());
        } else {
          this.infoCircelWindow.close();
        }
      });
    },

    addCircle(lnglat) {
      this.circle = new AMap.Circle({
        borderWeight: 3,
        strokeColor: "#25D739",
        strokeWeight: 6,
        strokeOpacity: 0.2,
        fillOpacity: 0.4,
        // strokeStyle: 'dashed',//dashed solid
        strokeDasharray: [5, 5],
        // fillColor: '#33C5FA',
        zIndex: 50,
        bubble: true,//使得鼠标事件渗透到地图上
      })
      this.circle.setCenter(lnglat);
      this.circle.setRadius(this.fenceData.radius);
      this.map.add(this.circle);
      // 缩放地图到合适的视野级别
      this.map.setFitView(this.circle,true);//[this.circle,this.circleMarker]
      // this.map.setCenter(lnglat)
    },

    startEditCircle() {
      var circleArr = this.map.getAllOverlays('circle');
      if (0 == circleArr.length) {
        this.$message.error('请先点击界面创建围栏');
        return;
      }
      AMap.plugin('AMap.CircleEditor', () => {
        this.circleEditor = new AMap.CircleEditor(this.map, this.circle)

        this.circleEditor.open();

        this.circleEditor.on('move', (event) => {
          console.log('触发事件：move', event)
        })
        this.circleEditor.on('adjust', (event) => {
          console.log('触发事件：adjust', event)
          this.fenceData.radius = event.radius;
        })
        this.circleEditor.on('end', (event) => {
          console.log('触发事件： end', event)
          // event.target 即为编辑后的圆形对象
        })
      });
    },
    next(type) {
      if (1 == type) {
        var circleArr = this.map.getAllOverlays('circle');
        if (0 == circleArr.length) {
          this.$message.error('请先点击界面创建围栏');
          return;
        }
        if (null != this.circleEditor) {
          this.circleEditor.close();
        }
      } else if (2 == type) {
        var polygonArr = this.map.getAllOverlays('polygon');
        if (0 == polygonArr.length) {
          this.$message.error('请先选择城市');
          return;
        }
      }
      this.fenceData.type = type
      this.fenceData.onSwitch = 1
      if(1 == this.funcType) {
        this.addPage = true;
        setTimeout(() => {
            this.$refs.addView.getParentData(this.fenceData); 
        }, 200);
      }else if(2 == this.funcType) {
        this.editPage = true;
        setTimeout(() => {
            this.$refs.editView.getParentData(this.fenceData); 
        }, 200);
      }
    },

    addPolygon(level, adcode) {
      
      AMap.plugin('AMap.DistrictSearch', () => {
        var districtSearch = new AMap.DistrictSearch({
          // 关键字对应的行政区级别，country表示国家
          level: level,//country：国家 province：省/直辖市 city：市 district：区/县 biz_area：商圈
          //  显示下级行政区级数，1表示返回下一级行政区
          subdistrict: 0,
          extensions: 'all'
        })
        // 搜索所有省/直辖市信息
        districtSearch.search(adcode, (status, result) => {
          // 查询成功时，result即为对应的行政区信息
          if (status == 'complete' && result.info == 'OK') {
            // console.log(status, result);
            this.addPolygonEx(result.districtList[0].boundaries);
          }
        })
      })
    },

    addPolygonEx(bounds) {
      if (bounds) {
        for (var i = 0; i < bounds.length; i += 1) {//构造MultiPolygon的path
          bounds[i] = [bounds[i]]
        }
      }
      this.polygon = new AMap.Polygon({
        strokeWeight: 2,
        strokeColor: '#CC66CC',
        fillColor: '#CCF3FF',
        fillOpacity: 0.2,
      });
      this.polygon.setPath(bounds);
      this.map.add(this.polygon);
      this.map.setFitView(this.polygon,true);

      this.polygon.on('mouseover', () => {
        this.polygon.setOptions({
          fillOpacity: 0.7,
          fillColor: '#7bccc4'
        })
      })
      this.polygon.on('mouseout', () => {
        this.polygon.setOptions({
          fillOpacity: 0.5,
          fillColor: '#ccebc5'
        })
      })
    },

    getCircelAddress(e) {
      var cityLevel = 'country'
      if (1 == e.level) {
        cityLevel = 'province';
      } else if (2 == e.level) {
        cityLevel = 'city';
      } else if (3 == e.level) {
        cityLevel = 'district';
      } else if (4 == e.level) {
        cityLevel = 'district';
      }
      AMap.plugin('AMap.DistrictSearch', () => {
        var districtSearch = new AMap.DistrictSearch({
          // 关键字对应的行政区级别，country表示国家
          level: cityLevel,//country：国家 province：省/直辖市 city：市 district：区/县 biz_area：商圈
          //  显示下级行政区级数，1表示返回下一级行政区
          subdistrict: 0,
          extensions: 'all'
        })
        // 搜索所有省/直辖市信息
        districtSearch.search(e.adcode, (status, result) => {
          // 查询成功时，result即为对应的行政区信息
          if (status == 'complete' && result.info == 'OK') {
            var data = result.districtList[0];
            this.map.setCenter([data.center.lng,data.center.lat])
          }
        })
      })
    },

    getPolygonAddress(e) {
      // var city = '中国'
      var cityLevel = 'country'
      //country：国家 province：省/直辖市 city：市 district：区/县 biz_area：商圈
      if (1 == e.level) {
        cityLevel = 'province';
        this.fenceData.districtAddress = e.selectProvince
      } else if (2 == e.level) {
        cityLevel = 'city';
        this.fenceData.districtAddress = e.selectProvince + '#' + e.selectCity
      } else if (3 == e.level) {
        cityLevel = 'district';
        this.fenceData.districtAddress = e.selectProvince + '#' + e.selectCity + '#' + e.selectDistrict
      } else if (4 == e.level) {
        cityLevel = 'district';
      }
      this.fenceData.adcode = e.adcode
      this.fenceData.level = e.level
      this.fenceData.cityLevel = cityLevel;
      this.addPolygon(cityLevel, e.adcode);
    },

    cancel(type) {
      if (1 == type) {
        this.circelView = false;
      } else if (2 == type) {
        this.polygonView = false;
      }
      if(1 == this.funcType) {
        this.map.clearMap();
      }else if(2 == this.funcType) {
        this.titleView = true
      }
    },
    addDevice() {
      this.devicePage = true
      setTimeout(() => {
        this.$refs.deviceView.setFenceData(this.rowData); 
    }, 200);
    },
    switchNotice() {
      this.managerPage = true
    },
    editFence() {
      this.titleView = false
      if (1 == this.rowData.type) {//自定义圆形
        this.title = this.rowData.name
        this.circelView = true;
        this.polygonView = false;
      } else if (2 == this.rowData.type) {//行政区
        this.title = this.rowData.name
        this.circelView = false;
        this.polygonView = true;
      }
    },
    rowClick(row) {
      this.funcType = 2
      if(this.rowData.uuid == row.uuid) return
      this.rowData = row
      Object.assign(this.fenceData, this.rowData);
      this.seeFence()
      this.getFenceDeviceSgInfos();
    },
    turnOn(row) {
      if(row.fenceSwitch == true) {
        this.fenceData.onSwitch = 1
      }else {
        this.fenceData.onSwitch = 0
      }
      
      let param = {
        unitUuid: session.getLoginInfo().unitUuid,
        uuid: row.uuid,
        onSwitch:this.fenceData.onSwitch
      }
      editFenceInfo(param).then(res => {
        if (res.data.code == 200) {
          // this.$message.success({ message: '删除成功' });
          this.getFenceInfos()
        } else {
          this.$message.error({ message: res.data.message });
          this.fenceData.fenceSwitch = !this.fenceData.fenceSwitch
        }
      })
    },
    update() {
      this.cancel(this.fenceData.type);
      this.getFenceInfos();
    },
    getFenceInfos() {
      let param = {
        unitUuid: session.getLoginInfo().unitUuid,
        page: 0,
        size: this.MAXSIZE,
      }
      getFenceInfos(param).then(res => {
        if (res.data.code == 200) {
          this.dataList = res.data.dataInfo;
        } else {
          this.dataList.splice(0, this.dataList.length);
          this.emptyText = "暂无数据"
        }
      })
    },

    deleteFence(row) {
      this.$confirm('确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          unitUuid: session.getLoginInfo().unitUuid,
          uuid: row.uuid
        }
        deleteFenceInfo(param).then(res => {
          if (res.data.code == 200) {
            this.$message.success({ message: '删除成功' });
            this.getFenceInfos()
          } else {
            this.$message.error({ message: res.data.message });
          }
        })
      }).catch(() => {});

    },
    updateManager(data) {
      let param = {
        unitUuid: session.getLoginInfo().unitUuid,
        uuid: this.rowData.uuid,
        noticeUuid:data.uuid
      }
      editFenceInfo(param).then(res => {
        if (res.data.code == 200) {
          this.$message.success({ message: '修改成功' });
          this.getFenceInfos()
        } else {
          this.$message.error({ message: res.data.message });
        }
      })
    },
    updateWear(data,type) {
      let param = {
        fenceUuid: this.rowData.uuid,
        type: type,
        deviceIdInfos:JSON.stringify(data)
      }
      updateFenceDeviceInfo(param).then(res => {
        if (res.data.code == 200) {
          this.$message.success({ message: '新增成功' });
          this.getFenceInfos()
        } else {
          this.$message.error({ message: res.data.message });
        }
      })
    },

    getFenceDeviceSgInfos() {
      let param = {
        fenceUuid: this.rowData.uuid,
        page: 0,
        size:1000
      }
      getFenceDeviceSgInfos(param).then(res => {
        if (res.data.code == 200) {
          this.addDeviceMarker(res.data.dataInfo);
        } 
      })
    },

    addDeviceMarker(dataList) {
      var markers = []
      this.fenceInNum = 0
      this.fenceOutNum = 0
      this.fenceNoNum = 0
      dataList.forEach(data => {
        if((data.lat != '0') ||(data.lng != '0')) {
          var marker = null
          if(data.state == 1) {
            marker = new AMap.Marker({
              position: new AMap.LngLat(data.lng, data.lat),//位置
              icon: this.iconBlue, // 添加 Icon 实例
            })
          }else {
            marker = new AMap.Marker({
              position: new AMap.LngLat(data.lng, data.lat),//位置
              icon: this.iconRed, // 添加 Icon 实例
            })
          }
          if(0 == data.mode) {
            data.modeName = 'GPS'
          }else if(1 == data.mode) {
            data.modeName = 'LBS'
          } else{
            data.modeName = 'WIFI'
          }
          if(data.state == 1) {//围栏内
            this.fenceInNum ++
          }else if(data.state == 2) {//围栏外
            this.fenceOutNum ++
          }else if(data.state == 3) {//未知
            this.fenceNoNum ++
          }
          marker.on('click', () => {
            this.infoWindow.setContent(mapInfo.getHtml(data,data));
            if(!this.infoWindow.getIsOpen()) {
                this.infoWindow.open(this.map, marker.getPosition());
            }else {
              this.infoWindow.close();
            }
          });
          markers.push(marker)
        }else {
          this.fenceNoNum ++
        }
      });
      if(markers.length > 0) {
        var overlayGroups = new AMap.OverlayGroup(markers);
        this.map.add(overlayGroups);
      }
    },

  }
}